import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M0 4.3 1.3 3 8 9.7 14.7 3 16 4.3l-8 8-8-8Z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgComponent

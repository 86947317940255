import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";

import styled from "styled-components";

import SongHeeho from "assets/member/SongHeeho.png";
import SongWooCheol from "assets/member/SongWooCheol.png";
import KimYeaJi from "assets/member/KimYeaJi.png";

import CommonLayout from "components/templates/CommonLayout";
import DesktopLimit from "components/templates/DesktopLimit";
import PageNavigation from "components/templates/PageNavigation";
import Text from "components/atoms/Text";
import FlexBox from "components/atoms/FlexBox";
import MemberItem from "components/organisms/team/MemberItem";

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: var(--color-White);
`;

const HeroTextWrap = styled.div`
  max-width: 480px;
`;

const MemberListWrap = styled.div`
  margin: 0 auto;
  padding: ${(props) =>
    props.isMobile ? " 40px 0 120px 0" : "80px 0 160px 0"};
  width: 100%;
  max-width: 720px;
`;

const TabButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 64px;
  background-color: var(--color-White);
`;

const TabButton = styled.button`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.width || "33.3%"};
  height: 64px;
  background-color: var(--color-White);
  border: transparent;
  border-radius: 0;
  outline: none;
  cursor: pointer;
`;

const ActiveBar = styled.span`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: ${(props) => props.selected};
  width: 100%;
  height: 3px;
  background-color: var(--color-Key);
`;

function Member(props) {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [selectedTab, setSelectedTab] = useState("SongHeeho"); // 기본값은 SongHeeho

  const handleTabClick = (tabName) => {
    setSelectedTab(tabName);
  };

  return (
    <CommonLayout>
      <PageNavigation pageName={"변호사 소개"} pageNavName={"변호사 소개"} />
      <Container>
        <DesktopLimit padding={isMobile && "0"}>
          <FlexBox flexDirection={"column"} alignItems={"center"}>
            <Text
              margin={"80px 0 0 0"}
              size={"14"}
              color={"var(--color-Key)"}
              fontWeight={"800"}
              textAlign={"center"}
              letterSpacing={"8px"}
            >
              OUR TEAM
            </Text>
            <HeroTextWrap>
              <Text
                margin={isMobile ? "40px 0 80px 0" : "40px 0 0 0"}
                size={"14"}
                color={"var(--color-SubBlack)"}
                textAlign={"center"}
                letterSpacing={"2px"}
              >
                법무법인 해태는 {isMobile && <br />}
                <strong>최고의 전문가로 구성된 팀</strong>
                입니다.
              </Text>
            </HeroTextWrap>
          </FlexBox>
          {isMobile && (
            <TabButtonWrap>
              <TabButton
                className={"SongHeeho"}
                onClick={() => handleTabClick("SongHeeho")}
              >
                <Text
                  size={"12"}
                  color={
                    selectedTab === "SongHeeho"
                      ? "var(--color-SubBlack)"
                      : "var(--color-Grey2)"
                  }
                >
                  대표 변호사
                </Text>
                <Text
                  margin={"4px 0 0 0"}
                  size={"16"}
                  fontWeight={"600"}
                  letterSpacing={"2px"}
                  color={
                    selectedTab === "SongHeeho"
                      ? "var(--color-Black)"
                      : "var(--color-Grey2)"
                  }
                >
                  송희호
                </Text>
                <ActiveBar
                  selected={selectedTab === "SongHeeho" ? "block" : "none"}
                />
              </TabButton>
              <TabButton
                className={"SongWooCheol"}
                onClick={() => handleTabClick("SongWooCheol")}
                width={"33.4%"}
              >
                <Text
                  size={"12"}
                  color={
                    selectedTab === "SongWooCheol"
                      ? "var(--color-SubBlack)"
                      : "var(--color-Grey2)"
                  }
                >
                  변호사
                </Text>
                <Text
                  margin={"4px 0 0 0"}
                  size={"16"}
                  fontWeight={"600"}
                  letterSpacing={"2px"}
                  color={
                    selectedTab === "SongWooCheol"
                      ? "var(--color-Black)"
                      : "var(--color-Grey2)"
                  }
                >
                  송우철
                </Text>
                <ActiveBar
                  selected={selectedTab === "SongWooCheol" ? "block" : "none"}
                />
              </TabButton>
              <TabButton
                className="KimYeaJi"
                onClick={() => handleTabClick("KimYeaJi")}
              >
                <Text
                  size={"12"}
                  color={
                    selectedTab === "KimYeaJi"
                      ? "var(--color-SubBlack)"
                      : "var(--color-Grey2)"
                  }
                >
                  변호사
                </Text>
                <Text
                  margin={"4px 0 0 0"}
                  size={"16"}
                  fontWeight={"600"}
                  letterSpacing={"2px"}
                  color={
                    selectedTab === "KimYeaJi"
                      ? "var(--color-Black)"
                      : "var(--color-Grey2)"
                  }
                >
                  김예지
                </Text>
                <ActiveBar
                  selected={selectedTab === "KimYeaJi" ? "block" : "none"}
                />
              </TabButton>
            </TabButtonWrap>
          )}
          <MemberListWrap isMobile={isMobile}>
            <MemberItem
              className={"SongHeeho"}
              isVisible={isMobile ? selectedTab === "SongHeeho" : "true"}
              bgImage={SongHeeho}
              memberPosition={"대표 변호사"}
              memberName={"송희호"}
              memberDescription={
                "광주지방법원 판사를 시작으로 광주고등법원 판사, 광주지방법원 부장판사, 전주지방법원 정읍지원장 등을 거쳐 광주지방법원 목포지원장을 끝으로 23년간의 법관생활을 마치고 변호사 활동과 함께 광주지방국세청 고문변호사, 전라남도 행정심판위원회 위원 등의 활동을 하였습니다."
              }
              abItemText={[
                "전남대학교 법과대학",
                "중국사회과학원 법률연구소 유학",
              ]}
              careerFirstLineItem={[
                "제 32회 사법시험 합격",
                "사법연수원 제 22기 수료",
                "광주지방법원, 광주고등법원 판사",
                "광주지방법원 부장판사",
                "전주지방법원 정읍지원장",
                "광주지방법원 목포지원장",
                "목포시 선거관리위원장",
                "광주 서구 선거관리위원장",
                "나주시 선거관리위원장",
                "정읍시 선거관리위원장",
              ]}
              careerSecondLineItem={[
                "광주지방국세청 고문변호사",
                "전라남도 행정심판위원회 위원",
                "전남대학교 법학전문대학원 겸임교수",
                "",
                "2012년 변호사회 선정 우수법관",
              ]}
            />
            <MemberItem
              className={"SongWooCheol"}
              isVisible={isMobile ? selectedTab === "SongWooCheol" : "true"}
              bgImage={SongWooCheol}
              memberPosition={"변호사"}
              memberName={"송우철"}
              abItemText={["광덕고등학교", "건국대학교 법과대학"]}
              careerFirstLineItem={[
                "제 8회 변호사시험 합격",
                "광주광역시 민간위탁운영심의위원회 위원",
                "광주광역시 서구 법률자문관",
                "광주과학기술원(GIST) 안심신고 변호사",
                "광주본부세관 납세자보호위원회 위원",
              ]}
              careerSecondLineItem={[
                "한국철도공사 사업개발위원회 외부위원",
                "대한변호사협회 북한이탈주민법률지원 변호사단",
                "국방부 사망 장병 유족을 위한 국선변호사",
                "해군검찰단 군범죄 피해자 국선변호사",
                "공군본부 성폭력범죄피해자 국선변호사",
              ]}
            />
            <MemberItem
              className={"KimYeaJi"}
              isVisible={isMobile ? selectedTab === "KimYeaJi" : "true"}
              bgImage={KimYeaJi}
              memberPosition={"변호사"}
              memberName={"김예지"}
              abItemText={["성신여자대학교 법과대학"]}
              careerFirstLineItem={[
                "제 9회 변호사시험 합격",
                "광주광역시 청원심사위원회 위원",
                "광주광역시 자치분권협의위원회 위원",
                "광주광역시 보육정책위원회 위원",
                "광주광역시 제안심사위원회 위원",
                "광주광역시교육청 공익제보위원회 위원",
                "광주광역시교육청·전라남도교육청 법률지원단",
              ]}
              careerSecondLineItem={[
                "한국철도공사 사업개발위원회 외부위원",
                "국방부 사망 장병 유족을 위한 국선변호사",
                "공군본부 성폭력범죄피해자 국선변호사",
                "광주수창초등학교 고문변호사",
                "광주·전남지방병무청 생계곤란심의위원회 위원",
              ]}
            />
          </MemberListWrap>
        </DesktopLimit>
      </Container>
    </CommonLayout>
  );
}

export default Member;

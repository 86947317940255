import React from "react";

import styled from "styled-components";

const Container = styled.div`
  padding: ${(props) => props.padding || "0 40px"};
  width: 100%;
  max-width: ${(props) => props.maxWidth || "1480px"};
`;

function DesktopLimit(props) {
  return (
    <Container maxWidth={props.maxWidth} padding={props.padding}>
      {props.children}
    </Container>
  );
}

export default DesktopLimit;

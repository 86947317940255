import * as React from "react";
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#1E1E1E"
      fillRule="evenodd"
      d="M7.11 8 0 15.11l.89.89L8 8.89 15.11 16l.89-.89L8.89 8 16 .89 15.11 0 8 7.11.89 0 0 .89 7.11 8Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgComponent;

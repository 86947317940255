import React from "react";

import styled from "styled-components";

import Text from "components/atoms/Text";
import FlexBox from "components/atoms/FlexBox";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
  width: 100%;
  background-color: var(--color-Base);
`;

function PageNavigation(props) {
  return (
    <Container>
      <Text
        size={"24"}
        color={"var(--color-Key)"}
        margin={"0 0 4px 0"}
        fontWeight={"600"}
      >
        {props.pageName}
      </Text>
      <FlexBox alignItems={"center"}>
        <Text size={"12"} color={"var(--color-SubBlack)"}>
          홈
        </Text>
        <Text size={"12"} color={"var(--color-SubBlack)"} margin={"0 4px"}>
          &gt;
        </Text>
        <Text size={"12"} color={"var(--color-SubBlack)"}>
          {props.pageNavName}
        </Text>
      </FlexBox>
    </Container>
  );
}

export default PageNavigation;

import React from "react";
import { useMediaQuery } from "react-responsive";

import styled from "styled-components";

import BulletIcon from "assets/icons/BulletIcon";

import DesktopLimit from "components/templates/DesktopLimit";
import FlexBox from "components/atoms/FlexBox";
import Text from "components/atoms/Text";

const Container = styled.div`
  padding: ${(props) => (props.isMobile ? "0" : "80px 0")};
  width: 100%;
  border-bottom: ${(props) =>
    props.isMobile ? "" : "solid 1px var(--color-Line)"};
  transition: all 150ms ease-in;

  &:first-child {
    padding: ${(props) => (props.isMobile ? "0 0 40px 0" : "0 0 80px 0")};
  }

  &:last-child {
    border-bottom: transparent;
  }
`;

const FirstSection = styled.div`
  display: flex;
  flex-direction: ${(props) => props.isMobile && "column"};
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => props.isMobile && "0 24px"};
`;

const PhotoFrame = styled.div`
  margin: ${(props) => (props.isMobile ? "24px 0 0 0" : "0 80px 0 0")};
  width: 100%;
  width: ${(props) => (props.isMobile ? "100%" : "320px")};
  max-width: 320px;
  height: 466.56px;
  border-radius: 8px;
  overflow: hidden;
`;

const MemberImage = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.bgImage});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

const AcademicBackground = styled.div`
  margin: 40px auto 0 auto;
  padding: ${(props) => props.isMobile && "0 24px"};
  width: 100%;
  max-width: ${(props) => props.isMobile && "320px"};
`;

const SubTitleBar = styled.div`
  margin: 0 12px 0 0;
  width: 2px;
  height: 20px;
  background-color: var(--color-Key);
`;

const AcademicBackgroundList = styled.div`
  margin: 16px 0 0 14px;
  width: calc(100% - 14px);
`;

const ABListItem = styled.li`
  margin: 0 0 8px 0;
  list-style: none;

  &:last-child {
    margin: 0;
  }
`;

const Career = styled.div`
  margin: 40px auto 0 auto;
  padding: ${(props) => props.isMobile && "0 24px"};
  width: 100%;
  max-width: ${(props) => props.isMobile && "320px"};
`;

const CareerList = styled.div`
  margin: ${(props) => (props.isMobile ? "0 0 8px 0" : "0")};
  width: ${(props) => (props.isMobile ? "calc(100% - 14px)" : "50%")};
`;

const CareerListItem = styled.li`
  display: flex;
  align-items: center;
  margin: 0 0 8px 0;
  height: 20px;
  list-style: none;

  &:last-child {
    margin: 0;
  }
`;

const BulletIconWrap = styled.div`
  position: relative;
  top: -1px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 2px 0 0;
  width: 20px;
  height: 20px;
`;

function MemberItem(props) {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Container
      isMobile={isMobile}
      isVisible={props.isVisible}
      style={{ display: props.isVisible ? "block" : "none" }}
    >
      <DesktopLimit maxWidth={"720px"} padding={isMobile && "0"}>
        <FirstSection isMobile={isMobile}>
          <PhotoFrame isMobile={isMobile}>
            <MemberImage bgImage={props.bgImage}></MemberImage>
          </PhotoFrame>
          <FlexBox flexDirection={"column"} width={"100%"} maxWidth={"320px"}>
            <Text
              margin={isMobile && "24px 0 0 0"}
              fontFamily={"NanumMyeongjo"}
              size={"16"}
            >
              {props.memberPosition}
            </Text>
            <Text
              margin={isMobile ? "8px 0 0 0" : "16px 0 0 0"}
              fontFamily={"NanumMyeongjo"}
              size={isMobile ? "24" : "32"}
              fontWeight={"800"}
              letterSpacing={isMobile ? "12px" : "20px"}
            >
              {props.memberName}
            </Text>
            {props.memberDescription && (
              <Text
                margin={isMobile ? "24px 0 0 0" : "40px 0 0 0"}
                size={"14"}
                color={"var(--color-SubBlack)"}
                lineHeight={"1.6"}
              >
                {props.memberDescription}
              </Text>
            )}
          </FlexBox>
        </FirstSection>
        <AcademicBackground isMobile={isMobile}>
          <FlexBox alignItems={"center"}>
            <SubTitleBar />
            <Text margin={"2px 0 0 0"} size={"16"} fontWeight={"600"}>
              학력
            </Text>
          </FlexBox>
          <AcademicBackgroundList>
            <ul>
              {props.abItemText.map((item, index) => (
                <ABListItem key={index}>
                  <Text
                    size={"14"}
                    letterSpacing={"1px"}
                    color={"var(--color-SubBlack)"}
                  >
                    {item}
                  </Text>
                </ABListItem>
              ))}
            </ul>
          </AcademicBackgroundList>
        </AcademicBackground>
        <Career isMobile={isMobile}>
          <FlexBox alignItems={"center"}>
            <SubTitleBar />
            <Text margin={"2px 0 0 0"} size={"16"} fontWeight={"600"}>
              경력
            </Text>
          </FlexBox>
          <FlexBox margin={"16px 0 0 0"} flexDirection={isMobile && "column"}>
            <CareerList isMobile={isMobile}>
              {props.careerFirstLineItem.map((item, index) => (
                <CareerListItem key={index}>
                  {item && (
                    <BulletIconWrap>
                      <BulletIcon scale={"0.8"} />
                    </BulletIconWrap>
                  )}
                  <Text
                    display={"inline"}
                    size={"14"}
                    letterSpacing={"-0.5px"}
                    color={"var(--color-SubBlack)"}
                  >
                    {item}
                  </Text>
                </CareerListItem>
              ))}
            </CareerList>
            <CareerList isMobile={isMobile}>
              {props.careerSecondLineItem.map((item, index) => (
                <CareerListItem key={index}>
                  {item && (
                    <BulletIconWrap>
                      <BulletIcon scale={"0.8"} />
                    </BulletIconWrap>
                  )}
                  <Text
                    display={"inline"}
                    size={"14"}
                    letterSpacing={"-0.5px"}
                    color={"var(--color-SubBlack)"}
                  >
                    {item}
                  </Text>
                </CareerListItem>
              ))}
            </CareerList>
          </FlexBox>
        </Career>
      </DesktopLimit>
    </Container>
  );
}

export default MemberItem;

import * as React from "react";

import styled from "styled-components";

const Svg = styled.svg`
  transform: scale(${(props) => props.scale});
`;

function SvgComponent(props) {
  return (
    <Svg width={10} height={10} scale={props.scale} fill="none" {...props}>
      <circle cx={5} cy={5} r={2} fill={props.color || "#231E1E"} />
    </Svg>
  );
}

export default SvgComponent;

import React from "react";

import styled, { css } from "styled-components";

const Size = css`
  ${(props) =>
    props.size === "11" &&
    css`
      font-size: 1.1rem;
    `}

  ${(props) =>
    props.size === "12" &&
    css`
      font-size: 1.2rem;
    `}

    ${(props) =>
    props.size === "13" &&
    css`
      font-size: 1.3rem;
    `}

    ${(props) =>
    props.size === "14" &&
    css`
      font-size: 1.4rem;
    `}

    ${(props) =>
    props.size === "16" &&
    css`
      font-size: 1.6rem;
    `}

    ${(props) =>
    props.size === "18" &&
    css`
      font-size: 1.8rem;
    `}

    ${(props) =>
    props.size === "20" &&
    css`
      font-size: 2rem;
    `}

    ${(props) =>
    props.size === "24" &&
    css`
      font-size: 2.4rem;
    `}

    ${(props) =>
    props.size === "32" &&
    css`
      font-size: 3.2rem;
    `}

    ${(props) =>
    props.size === "40" &&
    css`
      font-size: 4rem;
    `}

    ${(props) =>
    props.size === "48" &&
    css`
      font-size: 4.8rem;
    `}

    ${(props) =>
    props.size === "64" &&
    css`
      font-size: 6.4rem;
    `}
`;

const Container = styled.p`
  display: ${(props) => props.display};
  margin: ${(props) => props.margin || "0"};
  line-height: ${(props) => props.lineHeight || "1.4"};
  font-weight: ${(props) => props.fontWeight || "400"};
  color: ${(props) => props.color || "var(--color-Black)"};
  text-align: ${(props) => props.textAlign || "left"};
  letter-spacing: ${(props) => props.letterSpacing};
  font-family: ${(props) => props.fontFamily};
  transition: all 150ms ease-in;

  ${Size}
`;

function Text(props) {
  return (
    <Container
      display={props.display}
      margin={props.margin}
      lineHeight={props.lineHeight}
      fontWeight={props.fontWeight}
      color={props.color}
      textAlign={props.textAlign}
      letterSpacing={props.letterSpacing}
      fontFamily={props.fontFamily}
      size={props.size || "14"}
    >
      {props.children}
    </Container>
  );
}

export default Text;

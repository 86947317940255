import React from "react";

import styled from "styled-components";

const Container = styled.div`
  margin: 0;
  padding: 40px 0;
  width: 100%;
`;

const SectionWrap = styled.div`
  margin: 0 0 80px 0;
`;

const HeaderText = styled.p`
  margin: 0 0 24px 0;
  font-size: 16px;
  font-weight: 600;
`;

const DetailText = styled.p`
  font-size: 14px;
  color: var(--color-SubBlack);
`;

const NumberText = styled.span`
  display: block;
  margin: 0 0 24px 0;

  &:last-child {
    margin: 0;
  }
`;

const SubNumberText = styled.span`
  display: block;
  margin: ${(props) => props.margin || "0 0 16px 16px"};

  &:last-child {
    margin: 0 0 0 16px;
  }
`;

function Terms(props) {
  return (
    <Container>
      <SectionWrap>
        <HeaderText>
          제1조 (개인정보의 처리목적 및 처리하는 개인정보의 항목)
        </HeaderText>
        <DetailText>
          법무법인 해태가 개인정보를 처리하는 목적은 문의자의 문의처리입니다.
          처리하고 있는 개인정보는 목적 이외의 용도로는 이용되지 않으며, 이용
          목적이 변경되는 경우에는 개인정보보호법 제18조에 따라 별도의 동의를
          받는 등 필요한 조치를 이행할 예정입니다.
        </DetailText>
      </SectionWrap>
      <SectionWrap>
        <HeaderText>제2조 (개인정보의 처리 및 보유기간)</HeaderText>
        <DetailText>
          법무법인 해태는 법령에 따른 개인정보 보유, 이용기간 또는
          정보주체로부터 개인정보를 수집 시에 동의 받은 개인정보 보유, 이용기간
          내에서 개인정보를 처리 보유합니다. 정보주체의 개인정보는 원칙적으로
          해당 개인정보의 처리목적이 달성되거나 정보주체가 동의를 철회할 때까지
          보유 및 이용되며, 그 목적이 달성되면 지체 없이 파기됩니다.
        </DetailText>
      </SectionWrap>
      <SectionWrap>
        <HeaderText>제3조 (개인정보의 제3자 제공)</HeaderText>
        <DetailText>
          법무법인 해태는 정보주체의 개인정보를 제1조(개인정보의 처리 목적 및
          처리하는 개인정보의 항목)에서 명시한 범위 내에서만 처리하며,
          정보주체의 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조 및
          제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다. 법무법인
          해태는 현재 개인정보를 제3자에게 제공하지 않고 있습니다.
        </DetailText>
      </SectionWrap>
      <SectionWrap>
        <HeaderText>제4조 (정보주체의 권리․의무 및 행사방법)</HeaderText>
        <DetailText>
          <NumberText>
            ① 정보주체는 법무법인 해태에 대해 언제든지 다음 각 호의 개인정보
            보호 관련 권리를 행사할 수 있습니다.
          </NumberText>{" "}
          <SubNumberText>1. 개인정보 열람요구</SubNumberText>{" "}
          <SubNumberText>2. 오류 등이 있을 경우 정정 요구</SubNumberText>{" "}
          <SubNumberText>3. 삭제요구</SubNumberText>{" "}
          <SubNumberText margin={"0 0 24px 16px"}>
            4. 처리정지 요구{" "}
          </SubNumberText>
          <NumberText>
            ② 제1항에 따른 권리 행사는 법무법인 해태에 대해 개인정보보호법
            시행규칙 별지 제8호 서식에 따라 서면, 전자우편, 모사전송(FAX) 등을
            통하여 하실 수 있으며 법무법인 해태는 이에 대해 즉시 조치하겠습니다.
          </NumberText>{" "}
          <NumberText>
            ③ 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한
            경우에는 법무법인 해태는 정정 또는 삭제를 완료할 때까지 당해
            개인정보를 이용하거나 제공하지 않습니다.
          </NumberText>{" "}
          <NumberText>
            ④ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자
            등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법
            시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
          </NumberText>
        </DetailText>
      </SectionWrap>
      <SectionWrap>
        <HeaderText>제5조 (개인정보의 파기)</HeaderText>
        <DetailText>
          <NumberText>
            ① 법무법인 해태는 개인정보 보유기간의 경과, 처리목적 달성 등
            개인정보가 불필요하게 되었을 때에는 즉시 해당 개인정보를 파기합니다.
          </NumberText>{" "}
          <NumberText>
            ② 정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나 처리목적이
            달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야
            하는 경우에는 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나
            보관 장소를 달리하여 보존합니다.
          </NumberText>{" "}
          <NumberText>
            ③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.
          </NumberText>{" "}
          <SubNumberText>
            1. 파기절차 법무법인 해태는 파기 사유가 발생한 개인정보를 선정하고,
            법무법인 해태의 개인정보 보호책임자의 승인을 받아 개인정보를
            파기합니다.
          </SubNumberText>{" "}
          <SubNumberText>
            2. 파기방법 법무법인 해태는 전자적 파일 형태로 기록․저장된
            개인정보는 기록을 재생 할 수 없도록 파기하며, 종이 문서에 기록
            저장된 개인정보는 분쇄기로 분쇄하거 나 소각 하여 파기합니다.
          </SubNumberText>
        </DetailText>
      </SectionWrap>
      <SectionWrap>
        <HeaderText>제6조 (개인정보의 안전성 확보조치)</HeaderText>
        <DetailText>
          <NumberText>
            법무법인 해태는 개인정보의 안전성 확보를 위해 다음과 같은 조치를
            취하고 있습니다.{" "}
          </NumberText>
          <SubNumberText>
            1. 관리적 조치 : 내부관리계획 수립․시행, 정기적 직원 교육 등
          </SubNumberText>{" "}
          <SubNumberText>
            2. 기술적 조치 : 개인정보처리시스템 등의 접근권한 관리,
            접근통제시스템 설치, 암호화 및 보안프로그램 설치
          </SubNumberText>{" "}
          <SubNumberText>
            3. 물리적 조치 : 전산실, 자료보관실 등의 접근통제
          </SubNumberText>
        </DetailText>
      </SectionWrap>
      <SectionWrap>
        <HeaderText>제7조 (개인정보 보호책임자)</HeaderText>
        <DetailText>
          <NumberText>
            ① 법무법인 해태는 개인정보 처리에 관한 업무를 총괄해서 책임지고,
            개인정보 처리와 관련한 정보주체의 불만 처리 및 피해구제 등을 위하여
            아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
          </NumberText>{" "}
          <SubNumberText margin={"0 0 4px 16px"}>
            개인정보 보호책임자
          </SubNumberText>{" "}
          <SubNumberText margin={"0 0 4px 16px"}>- 성명 : 송우철</SubNumberText>{" "}
          <SubNumberText margin={"0 0 24px 16px"}>
            - 직책 : 변호사
          </SubNumberText>{" "}
          <NumberText>
            ② 정보주체는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를
            개인정보보호 담당 부서에 할 수 있습니다. 법무법인 해태는 정보주체의
            개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
          </NumberText>
        </DetailText>
      </SectionWrap>
      <SectionWrap>
        <HeaderText>제8조 (개인정보 처리방침의 변경)</HeaderText>
        <DetailText>
          법무법인 해태는 관련 법령 또는 내부규정의 변경에 따라 개인정보
          처리방침을 변경하는 경우에는 변경 및 시행의 시기, 변경된 내용을
          지속적으로 공개합니다.
        </DetailText>
      </SectionWrap>
      <SectionWrap>
        <HeaderText>제9조 (개인정보 처리방침의 변경)</HeaderText>
        <DetailText>
          <NumberText>
            정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담
            등을 문의하실 수 있습니다.{" "}
          </NumberText>
          <SubNumberText margin={"0 0 4px 16px"}>
            -개인정보 침해신고센터 (한국인터넷진흥원 운영)
          </SubNumberText>
          <SubNumberText>
            -개인정보 분쟁조정위원회 (한국인터넷진흥원 운영)
          </SubNumberText>
        </DetailText>
      </SectionWrap>
    </Container>
  );
}

export default Terms;

import React from "react";

import styled, { css } from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: ${(props) => props.flexDirection};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  width: ${(props) => props.width};
  max-width: ${(props) => props.maxWidth};
`;

function FlexBox(props) {
  return (
    <Container
      flexDirection={props.flexDirection}
      justifyContent={props.justifyContent}
      alignItems={props.alignItems}
      margin={props.margin}
      padding={props.padding}
      width={props.width}
      maxWidth={props.maxWidth}
    >
      {props.children}
    </Container>
  );
}

export default FlexBox;

import React from "react";
import { useMediaQuery } from "react-responsive";

import styled from "styled-components";

import FlexBox from "components/atoms/FlexBox";
import Text from "components/atoms/Text";

const Container = styled.div`
  margin: ${(props) => (props.isMobile ? "0 0 40px 0" : "0 0 120px 0")};
  width: 100%;
`;

const TitleSide = styled.div`
  padding: 0 40px 0 0;
`;

const TextWrap = styled.div`
  width: 100%;
  min-width: ${(props) => props.minWidth};
`;

const ContentSide = styled.div`
  padding: ${(props) => (props.isMobile ? "0" : "0 0 0 40px")};
  border-left: ${(props) =>
    props.isMobile ? "" : "solid 2px var(--color-Key)"};
`;

const Divider = styled.span`
  display: block;
  margin: 16px 0;
  width: 24px;
  height: 2px;
  background-color: var(--color-Key);
`;

function MajorItem(props) {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Container isMobile={isMobile}>
      <FlexBox flexDirection={isMobile && "column"}>
        <TitleSide>
          <TextWrap minWidth={isMobile ? "100%" : "120px"}>
            <Text
              size={isMobile ? "16" : "20"}
              textAlign={isMobile ? "left" : "right"}
            >
              {props.title}
            </Text>
          </TextWrap>
        </TitleSide>
        {isMobile && <Divider />}
        <ContentSide isMobile={isMobile}>
          <TextWrap minWidth={isMobile ? "100%" : "calc(100% - 202px)"}>
            <Text
              size={"14"}
              color={"var(--color-SubBlack)"}
              letterSpacing={"1px"}
            >
              {props.children}
            </Text>
          </TextWrap>
        </ContentSide>
      </FlexBox>
    </Container>
  );
}

export default MajorItem;

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import ScrollToTop from "components/atoms/ScrollToTop";

import LandingPage from "components/page/LandingPage";
import ContactPage from "components/page/ContactPage";
import AboutPage from "components/page/AboutPage";
import MajorPage from "components/page/MajorPage";
import MemberPage from "components/page/MemberPage";
import TermsPage from "components/page/TermsPage";

import "App.scss";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Router>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/major" element={<MajorPage />} />
            <Route path="/member" element={<MemberPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/terms" element={<TermsPage />} />
          </Routes>
        </Router>
      </header>
    </div>
  );
}

export default App;

import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";

import styled from "styled-components";

import ArrowIcon from "assets/icons/ArrowIcon";

import TermsDetail from "components/organisms/contact/TermsDetail";
import Text from "components/atoms/Text";

const Container = styled.div`
  margin: 16px 0 0 0;
  width: 100%;
`;

const TermBox = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  width: 100%;
  height: 48px;
  background-color: #301f09;
  border: transparent;
  outline: none;
  cursor: pointer;
  transition: all 150ms ease-in;
`;

const ToggleSign = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  transform: rotate(${(props) => (props.isOpen ? "180deg" : "0deg")});
`;

const TermDetailBox = styled.div`
  padding: ${(props) => (props.isOpen ? "16px" : "0 16px")};
  width: 100%;
  height: ${(props) => (props.isOpen ? "400px" : "0")};
  max-height: 400px;
  border: solid 1px var(--color-Outline);
  box-sizing: border-box;
  overflow: auto;
`;

function Terms(props) {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [isOpen, setIsOpen] = useState(false);

  const handleToggleTerms = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Container>
      <TermBox onClick={handleToggleTerms}>
        <Text margin={"1px 0 0 0"} size={"16"} color={"var(--color-White)"}>
          개인정보 처리방침
        </Text>
        <ToggleSign isOpen={isOpen}>
          <ArrowIcon />
        </ToggleSign>
      </TermBox>
      <TermDetailBox isOpen={isOpen}>
        <TermsDetail />
      </TermDetailBox>
    </Container>
  );
}

export default Terms;

import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";

import styled from "styled-components";

import CommonLayout from "components/templates/CommonLayout";
import DesktopLimit from "components/templates/DesktopLimit";
import PageNavigation from "components/templates/PageNavigation";
import Text from "components/atoms/Text";
import FlexBox from "components/atoms/FlexBox";
import TermsDetail from "components/organisms/contact/TermsDetail";
import TermsDetail2 from "components/organisms/contact/TermsDetail2";

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: var(--color-White);
`;

const TabButtonWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 40px auto 0 auto;
  width: 100%;
  max-width: ${(props) => (props.isMobile ? "100%" : "480px")};
  height: 56px;
  background-color: var(--color-White);
`;

const TabButton = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.isMobile ? "100%" : "240px")};
  height: 56px;
  background-color: var(--color-White);
  border: transparent;
  outline: none;
  cursor: pointer;
`;

const ActiveBar = styled.span`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  display: ${(props) => props.activeTab};
  width: 100%;
  height: 2px;
  background-color: var(--color-Key);
`;

const TermsWrap = styled.div`
  padding: ${(props) => (props.isMobile ? "80px 24px" : "80px 0")};
  width: 100%;
  background-color: var(--color-White);
  box-sizing: border-box;
`;

function Terms(props) {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [activeTab, setActiveTab] = useState("privacy");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <CommonLayout>
      <PageNavigation pageName={"이용약관"} pageNavName={"이용약관"} />
      <Container>
        <DesktopLimit maxWidth={"720px"} padding={isMobile && "0"}>
          <TabButtonWrap isMobile={isMobile}>
            <TabButton
              onClick={() => handleTabClick("privacy")}
              isMobile={isMobile}
            >
              <Text
                size={"16"}
                fontWeight={"600"}
                color={
                  activeTab === "privacy"
                    ? "var(--color-Black)"
                    : "var(--color-Grey1)"
                }
              >
                개인정보 처리방침
              </Text>
              {activeTab === "privacy" && <ActiveBar />}
            </TabButton>
            <TabButton
              onClick={() => handleTabClick("disclaimer")}
              isMobile={isMobile}
            >
              <Text
                size={"16"}
                fontWeight={"600"}
                color={
                  activeTab === "disclaimer"
                    ? "var(--color-Black)"
                    : "var(--color-Grey1)"
                }
              >
                면책공고
              </Text>
              {activeTab === "disclaimer" && <ActiveBar />}
            </TabButton>
          </TabButtonWrap>
          <TermsWrap isMobile={isMobile}>
            {activeTab === "privacy" && <TermsDetail />}
            {activeTab === "disclaimer" && <TermsDetail2 />}
          </TermsWrap>
        </DesktopLimit>
      </Container>
    </CommonLayout>
  );
}

export default Terms;

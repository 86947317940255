import React, { useState, useCallback } from "react";
import { useMediaQuery } from "react-responsive";

import styled from "styled-components";

import CheckIcon from "assets/icons/CheckActive";
import AgreeCircle from "assets/icons/AgreeCircle";

import CommonLayout from "components/templates/CommonLayout";
import DesktopLimit from "components/templates/DesktopLimit";
import PageNavigation from "components/templates/PageNavigation";
import Text from "components/atoms/Text";
import FlexBox from "components/atoms/FlexBox";
import Terms from "components/organisms/contact/Terms";
import LabelInput from "components/organisms/contact/LabelInput";
import { sendMail } from "api";

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: var(--color-White);
`;

const HeroTextWrap = styled.div`
  max-width: 560px;
`;

const ContactFormContainer = styled.div`
  margin: 80px 0 0 0;
  padding: ${(props) => props.isMobile && "0 24px"};
  width: 100%;
  max-width: 768px;
  box-sizing: border-box;
`;

const Label = styled.label`
  font-size: 16px;
  color: var(--color-Black);
`;

const CheckWrap = styled.div`
  display: flex;
  align-items: center;
  margin: ${(props) => props.margin};
`;

const CheckButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  background-color: transparent;
  border: transparent;
  outline: none;
  cursor: pointer;
`;

const AgreeButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: transparent;
  outline: none;
  cursor: pointer;
`;

const MainButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 4px;
  width: 160px;
  height: 48px;
  background-color: ${(props) => props.bgColor || "#AC9783"};
  border: transparent;
  outline: none;
  cursor: pointer;
  transition: all 150ms ease-in;

  &:hover {
    background-color: var(--color-Key);
  }
`;

function Contact(props) {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  // 상태 정의
  const [contactorName, setContactorName] = useState("");
  const [contactorNumber, setContactorNumber] = useState("");
  const [contactDetail, setContactDetail] = useState("");
  const [selectedOption, setSelectedOption] = useState("민사");
  const [isAgree, setIsAgree] = useState(false);

  // 개인정보 동의 처리
  const handleAgreeTerms = () => {
    setIsAgree(!isAgree);
  };

  // 옵션 선택 처리
  const handleOptionClick = useCallback((option) => {
    setSelectedOption(option);
  }, []);

  // 이메일 발송 처리
  const handleClickSendMail = useCallback(async () => {
    if (!isAgree) {
      alert("개인정보 처리방침에 동의해주세요.");
      return;
    }

    if (!contactorName || !contactorNumber || !contactDetail) {
      alert("모든 필드를 입력해주세요.");
      return;
    }

    const subject = `[문의][${selectedOption}] 해태 홈페이지 ${contactorName}님 문의`;
    const text = `
  성함: ${contactorName}
  연락처: ${contactorNumber}

  상담내용:
  ${contactDetail}
  `;
    const from = "lawhaetae@daum.net";
    const to = "lawhaetae@daum.net";

    try {
      const result = await sendMail(subject, text, from, to);
      alert("문의를 성공적으로 전송했습니다");
    } catch (e) {
      console.error("Email sending error:", e);
      alert("문의 전송에 실패했습니다. lawhaetae@daum.net 으로 문의해주세요");
    }
  }, [contactorName, contactorNumber, contactDetail, selectedOption, isAgree]);

  // 폼 취소 처리
  const handleCancel = () => {
    const confirmCancel = window.confirm("내용을 삭제하시겠습니까?");
    if (confirmCancel) {
      setContactorName("");
      setContactorNumber("");
      setContactDetail("");
      setSelectedOption("민사");
    }
  };

  return (
    <CommonLayout>
      <PageNavigation pageName={"문의하기"} pageNavName={"문의하기"} />
      <Container>
        <DesktopLimit padding={isMobile && "0"}>
          <FlexBox flexDirection={"column"} alignItems={"center"}>
            <Text
              margin={"80px 0 0 0"}
              size={"14"}
              color={"var(--color-Key)"}
              fontWeight={"800"}
              textAlign={"center"}
              letterSpacing={"8px"}
            >
              CONTACT US
            </Text>
            <HeroTextWrap>
              <Text
                margin={isMobile ? "40px 0 80px 0" : "40px 0 0 0"}
                size={"14"}
                color={"var(--color-SubBlack)"}
                textAlign={"center"}
                letterSpacing={"2px"}
              >
                법무법인 해태는 의뢰인을 위한 {isMobile && <br />}
                <strong>최고의 법률서비스</strong>를 제공하겠습니다. <br />
                상담받으실 내용에 대해서 기재해주시면 {isMobile && <br />}
                연락처를 통해 안내해드리겠습니다.
              </Text>
            </HeroTextWrap>
            <ContactFormContainer isMobile={isMobile}>
              <FlexBox flexDirection={isMobile && "column"}>
                <LabelInput
                  input
                  id={"name"}
                  type={"text"}
                  margin={isMobile ? "0 0 16px 0" : "0 40px 0 0"}
                  maxWidth={isMobile ? "100%" : "320px"}
                  labelName={"성함"}
                  placeholder={"의뢰인의 성함을 입력해주세요."}
                  value={contactorName}
                  onChange={(e) => setContactorName(e.target.value)}
                />
                <LabelInput
                  input
                  id={"phone"}
                  type={"text"}
                  maxWidth={isMobile ? "100%" : "calc(100% - 360px)"}
                  labelName={"연락처"}
                  placeholder={
                    "의뢰인의 연락처를 ‘-’ 없이 숫자만 입력해주세요."
                  }
                  value={contactorNumber}
                  onChange={(e) => setContactorNumber(e.target.value)}
                />
              </FlexBox>
              <FlexBox margin={"40px 0 0 0"} flexDirection={"column"}>
                <FlexBox alignItems={"flex-end"}>
                  <Label>상담분야</Label>
                  <Text
                    margin={"0 0 0 8px"}
                    size={"13"}
                    color={"var(--color-Key)"}
                  >
                    문의 분야를 선택해주세요.
                  </Text>
                </FlexBox>
                <FlexBox
                  margin={"16px 0 0 0"}
                  justifyContent={"space-between"}
                  width={"100%"}
                >
                  <FlexBox flexDirection={"column"}>
                    <CheckWrap margin={"0 0 16px 0"}>
                      <CheckButton
                        selected={selectedOption === "민사"}
                        onClick={() => handleOptionClick("민사")}
                      >
                        <CheckIcon
                          color={selectedOption === "민사" && "#A8221A"}
                        />
                      </CheckButton>
                      <Text margin={"0 0 0 8px"} size={"14"}>
                        민사
                      </Text>
                    </CheckWrap>
                    <CheckWrap>
                      <CheckButton
                        selected={selectedOption === "형사"}
                        onClick={() => handleOptionClick("형사")}
                      >
                        <CheckIcon
                          color={selectedOption === "형사" && "#A8221A"}
                        />
                      </CheckButton>
                      <Text margin={"0 0 0 8px"} size={"14"}>
                        형사
                      </Text>
                    </CheckWrap>
                  </FlexBox>
                  <FlexBox flexDirection={"column"}>
                    <CheckWrap margin={"0 0 16px 0"}>
                      <CheckButton
                        selected={selectedOption === "가사"}
                        onClick={() => handleOptionClick("가사")}
                      >
                        <CheckIcon
                          color={selectedOption === "가사" && "#A8221A"}
                        />
                      </CheckButton>
                      <Text margin={"0 0 0 8px"} size={"14"}>
                        가사
                      </Text>
                    </CheckWrap>
                    <CheckWrap>
                      <CheckButton
                        selected={selectedOption === "행정"}
                        onClick={() => handleOptionClick("행정")}
                      >
                        <CheckIcon
                          color={selectedOption === "행정" && "#A8221A"}
                        />
                      </CheckButton>
                      <Text margin={"0 0 0 8px"} size={"14"}>
                        행정
                      </Text>
                    </CheckWrap>
                  </FlexBox>
                  <FlexBox flexDirection={"column"}>
                    <CheckWrap margin={"0 0 16px 0"}>
                      <CheckButton
                        selected={selectedOption === "기업법무"}
                        onClick={() => handleOptionClick("기업법무")}
                      >
                        <CheckIcon
                          color={selectedOption === "기업법무" && "#A8221A"}
                        />
                      </CheckButton>
                      <Text margin={"0 0 0 8px"} size={"14"}>
                        기업법무
                      </Text>
                    </CheckWrap>
                    <CheckWrap>
                      <CheckButton
                        selected={selectedOption === "건설·부동산"}
                        onClick={() => handleOptionClick("건설·부동산")}
                      >
                        <CheckIcon
                          color={selectedOption === "건설·부동산" && "#A8221A"}
                        />
                      </CheckButton>
                      <Text margin={"0 0 0 8px"} size={"14"}>
                        건설·부동산
                      </Text>
                    </CheckWrap>
                  </FlexBox>
                </FlexBox>
              </FlexBox>
              <FlexBox margin={"40px 0 0 0"}>
                <LabelInput
                  textarea
                  id={"phone"}
                  type={"number"}
                  maxWidth={"100%"}
                  labelName={"연락처"}
                  placeholder={"외뢰인의 문의사항을 350자 이내로 기재해주세요."}
                  subText={"*글자수 350자 제한"}
                  value={contactDetail}
                  onChange={(e) => setContactDetail(e.target.value)}
                />
              </FlexBox>
              <Terms />
              <FlexBox
                margin={"8px 0 0 0"}
                justifyContent={"flex-end"}
                width={"100%"}
              >
                <AgreeButton onClick={handleAgreeTerms}>
                  <AgreeCircle
                    color={isAgree ? "var(--color-Key)" : "#DAD3CC"}
                  />
                  <Text margin={"0 0 0 8px"} size={"12"}>
                    개인정보 처리방침에 동의합니다.
                  </Text>
                </AgreeButton>
              </FlexBox>
              <FlexBox
                margin={"80px 0 160px 0"}
                justifyContent={"center"}
                width={"100%"}
              >
                <MainButton onClick={handleClickSendMail} bgColor={"#754213"}>
                  <Text
                    size={"16"}
                    color={"var(--color-White)"}
                    fontWeight={"600"}
                  >
                    작성완료
                  </Text>
                </MainButton>
                <MainButton onClick={handleCancel}>
                  <Text
                    size={"16"}
                    color={"var(--color-White)"}
                    fontWeight={"600"}
                  >
                    취소
                  </Text>
                </MainButton>
              </FlexBox>
            </ContactFormContainer>
          </FlexBox>
        </DesktopLimit>
      </Container>
    </CommonLayout>
  );
}

export default Contact;

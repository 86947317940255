import * as React from "react";
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#505050"
      d="M13.297 2H2.703C1.21 2 0 3.257 0 4.807v6.386C0 12.743 1.21 14 2.703 14h10.594C14.79 14 16 12.743 16 11.193V4.807C16 3.257 14.79 2 13.297 2Zm-1.541 6.804 2.861-1.398v2.312l-2.861-.914ZM2.703 3.436h10.594c.728 0 1.32.615 1.32 1.371V5.82l-5.812 2.84c-.512.25-1.098.25-1.61 0L1.383 5.82V4.807c0-.756.592-1.371 1.32-1.371Zm1.541 5.368-2.861.914V7.406l2.861 1.398Zm9.053 3.76H2.703c-.719 0-1.305-.6-1.32-1.344l4.72-1.508.503.246c.443.217.918.325 1.394.325.476 0 .95-.108 1.394-.325l.503-.246 4.72 1.508c-.015.744-.6 1.344-1.32 1.344Z"
    />
  </svg>
);
export default SvgComponent;

import React from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

import styled from "styled-components";

import FooterLogo from "assets/FooterLogo";

import FlexBox from "components/atoms/FlexBox";
import Text from "components/atoms/Text";

const Container = styled.div`
  width: 100%;
  padding: 40px 0 40px 0;
  background-color: var(--color-Footer);
`;

const TermLink = styled.button`
  margin: ${(props) => props.margin};
  background-color: transparent;
  border: transparent;
  outline: none;
  cursor: pointer;
`;

const Divide = styled.span`
  display: block;
  margin: 0 8px;
  width: 1px;
  height: 10px;
  background-color: var(--color-White);
`;

function GlobalFooter(props) {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTabletorPC = useMediaQuery({ minWidth: 768 });

  return (
    <Container>
      <FlexBox flexDirection={"column"} alignItems={"center"}>
        <FlexBox alignItems={"center"} margin={"0 0 40px 0"}>
          <Link to="/about">
            <TermLink>
              <Text
                size={"14"}
                fontWeight={"600"}
                color={"var(--color-White)"}
                textAlign={"center"}
              >
                회사소개
              </Text>
            </TermLink>
          </Link>
          <Link to="/terms">
            <TermLink margin={"0 16px"}>
              <Text
                size={"14"}
                fontWeight={"600"}
                color={"var(--color-White)"}
                textAlign={"center"}
              >
                면책공고
              </Text>
            </TermLink>
          </Link>
          <Link to="/terms">
            <TermLink>
              <Text
                size={"14"}
                fontWeight={"600"}
                color={"var(--color-White)"}
                textAlign={"center"}
              >
                개인정보처리방침
              </Text>
            </TermLink>
          </Link>
        </FlexBox>
        <FlexBox
          flexDirection={"column"}
          alignItems={"center"}
          margin={"0 0 40px 0"}
        >
          <FlexBox alignItems={"center"} margin={"0 0 4px 0"}>
            <Text size={"12"} color={"var(--color-White)"} textAlign={"center"}>
              상호명 : 법무법인 해태
            </Text>
            <Divide />
            <Text size={"12"} color={"var(--color-White)"} textAlign={"center"}>
              대표변호사 : 송희호
            </Text>
          </FlexBox>
          {isMobile && (
            <>
              <Text
                size={"12"}
                color={"var(--color-White)"}
                textAlign={"center"}
                margin={"0 0 4px 0"}
              >
                사업자등록번호 : 779-87-02084
              </Text>
            </>
          )}
          <FlexBox alignItems={"center"} margin={"0 0 4px 0"}>
            {isTabletorPC && (
              <>
                <Text
                  size={"12"}
                  color={"var(--color-White)"}
                  textAlign={"center"}
                >
                  사업자등록번호 : 779-87-02084
                </Text>
                <Divide />
              </>
            )}
            <Text size={"12"} color={"var(--color-White)"} textAlign={"center"}>
              대표전화 : 062-231-2300
            </Text>
            <Divide />
            <Text size={"12"} color={"var(--color-White)"} textAlign={"center"}>
              FAX : 062-228-2500
            </Text>
          </FlexBox>
          <Text size={"12"} color={"var(--color-White)"} textAlign={"center"}>
            주소 : 광주광역시 동구 지산로63번길 2 호산법조타운 206호
          </Text>
        </FlexBox>
        <FooterLogo />
        <Text
          size={"12"}
          color={"var(--color-White)"}
          margin={"40px 0 0 0"}
          textAlign={"center"}
        >
          COPYRIGHTⓒ2020 by {isMobile && <br />}
          법무법인해태. ALL RIGHTS RESERVED.
        </Text>
      </FlexBox>
    </Container>
  );
}

export default GlobalFooter;

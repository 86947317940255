import React from "react";
import { useMediaQuery } from "react-responsive";

import styled from "styled-components";

import CommonLayout from "components/templates/CommonLayout";
import DesktopLimit from "components/templates/DesktopLimit";
import PageNavigation from "components/templates/PageNavigation";
import Text from "components/atoms/Text";
import FlexBox from "components/atoms/FlexBox";
import MajorItem from "components/organisms/major/MajorItem";

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: var(--color-White);
`;

const HeroTextWrap = styled.div`
  max-width: 480px;
`;

const MajorListWrap = styled.div`
  padding: ${(props) => (props.isMobile ? "80px 0 120px 0" : "160px 0")};
  width: 100%;
  max-width: 800px;
`;

function Major(props) {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <CommonLayout>
      <PageNavigation
        pageName={"법무법인 해태 주요 업무"}
        pageNavName={"주요 업무"}
      />
      <Container>
        <DesktopLimit>
          <FlexBox flexDirection={"column"} alignItems={"center"}>
            <Text
              margin={isMobile ? "80px 0 0 0" : "40px 0 0 0"}
              size={"14"}
              color={"var(--color-Key)"}
              fontWeight={"800"}
              textAlign={"center"}
              letterSpacing={"8px"}
            >
              OUR MAJOR
            </Text>
            <HeroTextWrap>
              <Text
                margin={isMobile ? "80px 0 0 0" : "40px 0 0 0"}
                size={"14"}
                color={"var(--color-SubBlack)"}
                textAlign={"center"}
                letterSpacing={"2px"}
              >
                대표변호사인 송희호 변호사는 법관으로 재직 중 민사, 형사, 행정,
                가사, 도산(기업회생), 경매, 신청, 항고 등 소송관련 제반 업무를
                담당하여 처리하였던 경험과 법리를 바탕으로 아래와 같은 업무를
                처리합니다.
              </Text>
            </HeroTextWrap>
            <MajorListWrap isMobile={isMobile}>
              <MajorItem title={"민사"}>
                가압류·가처분 관련 신청, 손해배상, 부당이득반환, 구상금, 부동산
                관련, 공동소유 관련, 조합·종중·교회와 관련한 법적 분쟁 등
                민사사건 변론을 수행합니다. 이와 함께 민사 관련 법률 자문을
                수행합니다.
              </MajorItem>
              <MajorItem title={"형사"}>
                사기, 횡령, 배임, 명예훼손, 정보통신망법위반, 성폭력범죄,
                학교폭력 등 형사사건 변론을 수행합니다. 이와 함께 경찰 및 검찰
                수사 단계 참여와 형사 관련 법률 자문을 수행합니다.
              </MajorItem>
              <MajorItem title={"가사"}>
                이혼, 재산분할, 위자료, 상속, 유류분, 유언, 친자관계 확인,
                인지청구 및 부양료 청구, 성년후견개시심판, 입양 등과 관련한 업무
                및 가사 관련 법률 자문을 수행합니다.
              </MajorItem>
              <MajorItem title={"행정"}>
                행정심판청구, 집행정지결정신청, 항고소송, 소청심사사건,
                토지수용재결, 보상금 증액 관련 소송, 손실보상, 국가배상소송,
                입찰참가자격제한, 부정당업자제재처분 관련 소송, 과징금, 변상금,
                부담금 부과처분 취소소송, 보상금 증액소송, 정보비공개결정,
                정보공개거부처분 취소소송, 공무원·교원 징계 등 신분 관련 소송,
                조세처분 취소소송 등 행정사건의 변론을 수행합니다. 이와 함께
                행정 관련 법률 자문을 수행합니다.
              </MajorItem>
              <MajorItem title={"기업법무"}>
                기업의 설립 및 운영, 계약체결, 분쟁 등과 관련된 회사법,
                자본시장법, 공정거래법 등에 대한 전반적인 법률 자문뿐만 아니라
                각종 계약서의 검토ㆍ작성 등의 기업 일반 업무 전반에 대한
                법률서비스를 제공합니다.
              </MajorItem>
              <MajorItem title={"건설·부동산"}>
                공사대금 청구(설계변경, 계약금액조정, 간접비 청구소송 등 포함),
                지체상금 청구, 소유권이전등기말소 청구,
                조합설립인가처분무효확인, 관리처분계획취소, 조합총회결의무효확인
                청구, 일조·조망ㆍ소음ㆍ진동ㆍ분진 등 건설 관련 환경 분쟁,
                도시개발사업 환지계획, 실시계획인가 등에 관한 업무 및
                건설·부동산 관련 법률 자문을 수행합니다.
              </MajorItem>
            </MajorListWrap>
          </FlexBox>
        </DesktopLimit>
      </Container>
    </CommonLayout>
  );
}

export default Major;

import React from "react";

import styled from "styled-components";

const Container = styled.div`
  margin: 0;
  padding: 40px 0;
  width: 100%;
`;

const SectionWrap = styled.div`
  margin: 0 0 40px 0;
`;

const DetailText = styled.p`
  font-size: 14px;
  color: var(--color-SubBlack);
`;

function Terms(props) {
  return (
    <Container>
      <SectionWrap>
        <DetailText>
          법무법인 해태의 웹사이트에 있는 모든 내용들은 일반적인 정보를 제공할
          목적으로 게시된 것으로 법률적인 자문을 위한 것이 아닙니다.
        </DetailText>
      </SectionWrap>
      <SectionWrap>
        <DetailText>
          법무법인 해태의 웹사이트에서 얻은 정보로 인하여 문제가 발생하여 손해를
          입었다고 하더라도 법무법인 해태는 어떠한 책임도 지지 아니합니다.
        </DetailText>
      </SectionWrap>
      <SectionWrap>
        <DetailText>
          따라서, 법무법인 해태의 웹사이트에서 제공하는 정보에 근거하여 행동을
          하기에 앞서 반드시 법무법인 해태에 법률적 자문을 구하시기 바랍니다.
        </DetailText>
      </SectionWrap>
      <SectionWrap>
        <DetailText>
          법무법인 해태의 웹사이트에 게재된 자료상의 견해는 저자들의 개인적인
          견해이며, 법무법인 해태의 공식적인 견해가 아님을 참고하시기 바랍니다.
        </DetailText>
      </SectionWrap>
      <SectionWrap>
        <DetailText>
          법무법인 해태의 웹사이트에 있는 모든 정보에 대해서는 법무법인 해태가
          저작권을 가지며, 서면 허락 없이는 복제할 수 없습니다.
        </DetailText>
      </SectionWrap>
    </Container>
  );
}

export default Terms;

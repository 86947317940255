import React from "react";
import { useMediaQuery } from "react-responsive";

import styled, { css } from "styled-components";

import FirSecImg from "assets/png/AboutFirSecImage.png";

import CommonLayout from "components/templates/CommonLayout";
import DesktopLimit from "components/templates/DesktopLimit";
import PageNavigation from "components/templates/PageNavigation";
import Text from "components/atoms/Text";
import FlexBox from "components/atoms/FlexBox";

const FirstSection = styled.div`
  display: flex;
  justify-content: center;
  padding: 80px 0 120px 0;
  width: 100%;
  background-color: var(--color-Base2);
`;

const FirSecImage = styled.div`
  padding: 47.7% 0 0 0;
  width: calc(50% - 40px);
  background-image: url(${(props) => props.bgImage});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;

  ${(props) =>
    props.isTablet &&
    css`
      width: calc(50% - 20px);
    `}

  ${(props) =>
    props.isMobile &&
    css`
      padding: 102% 0 0 0;
      width: 100%;
    `}
`;

const TitleBar = styled.div`
  margin: 0 24px 0 0;
  width: 4px;
  height: 28px;
  background-color: var(--color-Key);
`;

const TitleUnderBar = styled.div`
  margin: 8px 0;
  width: 16px;
  height: 2px;
  background-color: var(--color-Key);
`;

const FirSecTextWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 0 80px;
  width: calc(50% - 40px);

  ${(props) =>
    props.isTablet &&
    css`
      margin: 0 0 0 40px;
      width: calc(50% - 20px);
    `}

  ${(props) =>
    props.isMobile &&
    css`
      margin: 40px 0 0 0;
      width: calc(100% - 48px);
    `}
`;

const FirTexItem = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 80px 0;

  ${(props) =>
    props.isTablet &&
    css`
      margin: 0 0 40px 0;
    `}

  &:last-child {
    margin: 0;
  }
`;

const HanjaFont = styled.span`
  font-family: auto;
`;

const SecondSection = styled.div`
  display: flex;
  justify-content: center;
  padding: ${(props) => (props.isMobile ? "80px 0" : "160px 0")};
  width: 100%;
  background-color: var(--color-White);
`;

const BoxWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
  align-items: center;
  margin: 40px 0 0 0;
  max-width: 1240px;
`;

const SecBox = styled.div`
  margin: ${(props) => props.margin};
  padding: 40px;
  width: 360px;
  max-width: 360px;
  border: solid 1px var(--color-BoxLine);
  background-color: var(--color-White);
  box-sizing: border-box;
  z-index: 1;

  ${(props) =>
    props.isTablet &&
    css`
      width: 240px;
      max-width: 240px;
    `}
`;

const CenterLine = styled.span`
  position: absolute;
  background-color: var(--color-BoxLine);

  ${(props) =>
    props.isTabletorPC &&
    css`
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      height: 1px;
    `}

  ${(props) =>
    props.isMobile &&
    css`
      left: 50%
      transform: translateX(-50%);
      width: 1px;
      height: 100%;
    `}
`;

const ThirdSection = styled.div`
  display: flex;
  justify-content: center;
  padding: ${(props) => (props.isMobile ? "80px 0" : "120px 0")};
  width: 100%;
  background-color: var(--color-Base2);
`;

const ThirdSecTextWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 80px 0 0 0;
  width: 100%;
`;

const HisTextWrap = styled.div`
  margin: 24px 0 0 0;
  width: calc(100% - 240px);
  max-width: 480px;

  ${(props) =>
    props.isMobileorTablet &&
    css`
      width: calc(100% - 200px);
    `}

  ${(props) =>
    props.isMobile &&
    css`
      width: 100%;
      max-width: unset;
    `}
`;

const HisMobileLine = styled.div`
  margin: 24px 0;
  width: 2px;
  height: 48px;
  background-color: #d4c7b6;
`;

const HisLineContainer = styled.div`
  position: absolute;
  top: 24px;
  left: 30%;
  width: 12px;
  height: 180px;

  ${(props) =>
    props.isMobileorTablet &&
    css`
      left: 25%;
    `}
`;

const HisLineRel = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const HisCircle = styled.span`
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: #d4c7b6;
`;

const HisLine = styled.span`
  width: 2px;
  height: 100%;
  background-color: #d4c7b6;
`;

const ForthSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 120px 0 160px 0;
  width: 100%;
  background-color: var(--color-White);
`;

const Map = styled.div`
  margin: 80px 0 0 0;
  width: 100%;
  max-width: 1200px;
  height: 400px;
  background-color: red;
`;

const ForthTextWrap = styled.div`
  width: 800px;
  max-width: 800px;

  ${(props) =>
    props.isTablet &&
    css`
      width: 640px;
      max-width: 640px;
    `}

  ${(props) =>
    props.isMobile &&
    css`
      width: calc(100% - 48px);
      max-width: 400px;
    `}
`;

const InfoItem = styled.div`
  margin: ${(props) => props.margin};
  width: 400px;
  max-width: 400px;

  ${(props) =>
    props.isTablet &&
    css`
      width: 320px;
      max-width: 320px;
    `}
`;

const SubTitleBar = styled.div`
  margin: 0 8px 0 0;
  width: 2px;
  height: 18px;
  background-color: var(--color-Key);
`;

function IntroductionPage(props) {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isMobileorTablet = useMediaQuery({ maxWidth: 1200 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1200 });
  const isTabletorPC = useMediaQuery({ minWidth: 768 });

  React.useEffect(() => {
    const naver = window["naver"];
    const position = new naver.maps.LatLng(35.1496754352072, 126.932863583716);
    const mapOptions = {
      center: position,
      zoom: 18,
    };

    const map = new naver.maps.Map("naver-map", mapOptions);
    const marker = new naver.maps.Marker({ position, map });
  }, []);

  return (
    <CommonLayout>
      <PageNavigation
        pageName={"법무법인 해태 소개"}
        pageNavName={"해태 소개"}
      />

      {/* 첫번째 섹션 */}
      <FirstSection>
        <DesktopLimit
          maxWidth={isMobile ? "560px" : "1080px"}
          padding={isMobile && "0"}
        >
          <FlexBox flexDirection={"column"} alignItems={"center"}>
            <Text
              size={"14"}
              color={"var(--color-Key)"}
              fontWeight={"800"}
              letterSpacing={"8px"}
            >
              ABOUT HAETAE
            </Text>
            <Text
              margin={isMobile ? "40px 0 0 0" : "80px 0 0 0"}
              size={"20"}
              letterSpacing={"2px"}
              textAlign={"center"}
            >
              법무법인 해태는 {isMobile && <br />}
              <strong>정의</strong>를 지향합니다.
            </Text>
            <FlexBox
              margin={"40px 0 0 0"}
              alignItems={"center"}
              {...(isMobile
                ? {
                    flexDirection: "column",
                  }
                : {})}
            >
              <FirSecImage
                isTablet={isTablet}
                isMobile={isMobile}
                bgImage={FirSecImg}
              />
              <FirSecTextWrap isTablet={isTablet} isMobile={isMobile}>
                {/* 해태 */}
                <FirTexItem isTablet={isTablet}>
                  <FlexBox
                    margin={"0 0 4px 0"}
                    alignItems={isMobile ? "flex-start" : "center"}
                    flexDirection={isMobile && "column"}
                  >
                    {!isMobile && <TitleBar />}
                    <Text size={isMobile ? "20" : "24"} fontWeight={"800"}>
                      해태
                    </Text>
                    {isMobile && <TitleUnderBar />}
                  </FlexBox>
                  <Text margin={isMobile ? "0" : "0 0 0 28px"}>
                    는 옳음과 그름(<HanjaFont>是非</HanjaFont>), 착한 것과 악한
                    것(<HanjaFont>善惡</HanjaFont>)을 판단하여 안다는 상상의
                    동물로, 바르지 못한 자를 들이 받고 옳지 못한 자를 문다는
                    정의를 상징하는 동물입니다.
                  </Text>
                </FirTexItem>

                {/* 법무법인 해태 */}
                <FirTexItem isTablet={isTablet}>
                  <FlexBox
                    margin={"0 0 4px 0"}
                    alignItems={isMobile ? "flex-start" : "center"}
                    flexDirection={isMobile && "column"}
                  >
                    {!isMobile && <TitleBar />}
                    <Text size={isMobile ? "20" : "24"} fontWeight={"800"}>
                      법무법인 해태
                    </Text>
                    {isMobile && <TitleUnderBar />}
                  </FlexBox>
                  <Text margin={isMobile ? "0" : "0 0 0 28px"}>
                    는 ‘해태’와 같이 옳음과 그름, 착한 것과 악한 것을 가려 내
                    당사자들의 억울함이 없도록 하여 정의를 세우는 길을 묵묵히 갈
                    것입니다. 또한 법무법인 해태는 구성원 모두가 아버지와 아들
                    및 부부로 구성되어 모든 변호사가 자기 일처럼 강한 책임감을
                    가지고 뭉쳐 있습니다.
                  </Text>
                </FirTexItem>

                {/* 대표 변호사 */}
                <FirTexItem isTablet={isTablet}>
                  <FlexBox
                    margin={"0 0 4px 0"}
                    alignItems={isMobile ? "flex-start" : "center"}
                    flexDirection={isMobile && "column"}
                  >
                    {!isMobile && <TitleBar />}
                    <Text size={isMobile ? "20" : "24"} fontWeight={"800"}>
                      대표 변호사
                    </Text>
                    {isMobile && <TitleUnderBar />}
                  </FlexBox>
                  <Text margin={isMobile ? "0" : "0 0 0 28px"}>
                    송희호 변호사는 법관 시절 변호사회가 뽑은 ‘우수 법관’으로
                    선정되었으며, 변호사 개업 이후에는 법관 시절의 경험과
                    법률지식을 바탕으로 많은 사건을 처리하며 좋은 성과를 올리고
                    있습니다. 대표 변호사를 비롯한 모든 구성원 변호사들은 모든
                    사건에 대하여 함께 연구하고 토론하여 그 결과를 바탕으로
                    서면을 작성하고 변론을 하고 있으며, 법원에 제출하는 모든
                    서면은 대표변호사가 직접 작성하거나 꼼꼼히 수정하고 있고,
                    당사자의 최종 승인을 얻어 법원에 제출하는 등 당사자와의
                    소통을 중시하고 있습니다.
                  </Text>
                </FirTexItem>
              </FirSecTextWrap>
            </FlexBox>
          </FlexBox>
        </DesktopLimit>
      </FirstSection>

      {/* 두번째 섹션 */}
      <SecondSection isMobile={isMobile}>
        <DesktopLimit maxWidth={"1240px"}>
          <FlexBox flexDirection={"column"} alignItems={"center"}>
            <Text
              size={"14"}
              color={"var(--color-Key)"}
              fontWeight={"800"}
              letterSpacing={"8px"}
            >
              OUR SERVICE
            </Text>
            <Text
              margin={isMobile ? "40px 0 0 0" : "80px 0 0 0"}
              size={"20"}
              letterSpacing={"2px"}
              textAlign={"center"}
            >
              법무법인 해태는 {isMobile && <br />}
              <strong>최고의 법률 서비스</strong>를 제공합니다.
            </Text>
            <BoxWrap isMobile={isMobile}>
              <CenterLine isMobile={isMobile} isTabletorPC={isTabletorPC} />
              <SecBox isTablet={isTablet}>
                {/* 최고 수준의 */}
                <FlexBox justifyContent={"space-between"} alignItems={"center"}>
                  <Text size={"18"} fontWeight={"800"}>
                    최고 수준의
                    <br />
                    법률 서비스
                  </Text>
                  <Text
                    size={"40"}
                    fontWeight={"800"}
                    color={"var(--color-Base2)"}
                  >
                    01
                  </Text>
                </FlexBox>
                <Text
                  margin={"24px 0 0 0"}
                  size={"14"}
                  color={"var(--color-Grey1)"}
                >
                  법관으로서의 실무 경험과 법률지식을 통하여 최고 수준의 법률
                  서비스를 제공합니다.
                </Text>
              </SecBox>

              {/* 감동을 주는 */}
              <SecBox
                isTablet={isTablet}
                margin={isTablet ? "0 24px" : isMobile ? "40px 0" : "0 80px"}
              >
                <FlexBox justifyContent={"space-between"} alignItems={"center"}>
                  <Text size={"18"} fontWeight={"800"}>
                    감동을 주는
                    <br />
                    법률 서비스
                  </Text>
                  <Text
                    size={"40"}
                    fontWeight={"800"}
                    color={"var(--color-Base2)"}
                  >
                    02
                  </Text>
                </FlexBox>
                <Text
                  margin={"24px 0 0 0"}
                  size={"14"}
                  color={"var(--color-Grey1)"}
                >
                  당사자의 이야기에 귀 기울여 한마디도 흘려듣지 않고, 모든 것을
                  쏟아 부어 조금의 후회도 남지 않는 법률 서비스를 제공합니다.
                </Text>
              </SecBox>

              {/* 승리하는 */}
              <SecBox isTablet={isTablet}>
                <FlexBox justifyContent={"space-between"} alignItems={"center"}>
                  <Text size={"18"} fontWeight={"800"}>
                    승리하는
                    <br />
                    법률 서비스
                  </Text>
                  <Text
                    size={"40"}
                    fontWeight={"800"}
                    color={"var(--color-Base2)"}
                  >
                    03
                  </Text>
                </FlexBox>
                <Text
                  margin={"24px 0 0 0"}
                  size={"14"}
                  color={"var(--color-Grey1)"}
                >
                  끊임없이 대법원 판례나 법률 이론을 찾아내고 연구하여 승리하는
                  법률 서비스를 제공합니다.
                </Text>
              </SecBox>
            </BoxWrap>
          </FlexBox>
        </DesktopLimit>
      </SecondSection>

      {/* 세번째 섹션 */}
      <ThirdSection isMobile={isMobile}>
        <DesktopLimit maxWidth={"840px"}>
          <FlexBox flexDirection={"column"} alignItems={"center"}>
            <Text
              size={"14"}
              color={"var(--color-Key)"}
              fontWeight={"800"}
              letterSpacing={"8px"}
            >
              OUR HISTORY
            </Text>
            <ThirdSecTextWrap>
              <FlexBox
                flexDirection={isMobile && "column"}
                justifyContent={"space-between"}
                width={"100%"}
              >
                <Text
                  size={isMobileorTablet ? "48" : "64"}
                  fontWeight={"800"}
                  color={"#A08B7F"}
                >
                  2016
                </Text>
                <HisTextWrap
                  isMobileorTablet={isMobileorTablet}
                  isMobile={isMobile}
                >
                  <Text size={"16"} color={"var(--color-SubBlack)"}>
                    송희호 대표변호사는 광주지방법원 부장판사, 광주지방법원
                    목포지원장을 거쳐 2016. 3. 1. ‘변호사 송희호 법률사무소’를
                    개설하였습니다.
                  </Text>
                </HisTextWrap>
              </FlexBox>
              {isMobile && <HisMobileLine />}
              <FlexBox
                flexDirection={isMobile && "column"}
                justifyContent={"space-between"}
                margin={isMobile ? "0" : "64px 0 0 0"}
                width={"100%"}
              >
                <Text
                  size={isMobileorTablet ? "48" : "64"}
                  fontWeight={"800"}
                  color={"#A08B7F"}
                >
                  2020
                </Text>
                <HisTextWrap
                  isMobileorTablet={isMobileorTablet}
                  isMobile={isMobile}
                >
                  <Text size={"16"} color={"var(--color-SubBlack)"}>
                    송희호 변호사는 법무부의 법무법인 인가를 받아 2020. 11. 3.
                    법무법인 해태를 설립하였습니다.
                    <br />
                    <br />
                    법무법인 해태는 시비(
                    <HanjaFont>是非</HanjaFont>)와 선악(
                    <HanjaFont>善惡</HanjaFont>)을 판단하여 안다는 상상의
                    동물인‘해태’를 법무법인의 이름으로 하여 시비와 선악을
                    올바르게 판단하여 정의로운 소송 결과를 이끌어 의뢰인의
                    억울함이 없도록 하겠다는 이념으로 설립되었습니다.
                  </Text>
                </HisTextWrap>
              </FlexBox>
              {!isMobile && (
                <HisLineContainer isMobileorTablet={isMobileorTablet}>
                  <HisLineRel>
                    <HisCircle />
                    <HisCircle style={{ bottom: "0" }} />
                    <HisLine />
                  </HisLineRel>
                </HisLineContainer>
              )}
            </ThirdSecTextWrap>
          </FlexBox>
        </DesktopLimit>
      </ThirdSection>

      {/* 네번째 섹션 */}
      <ForthSection>
        <DesktopLimit maxWidth={"1200px"}>
          <FlexBox flexDirection={"column"} alignItems={"center"}>
            <Text
              size={"14"}
              color={"var(--color-Key)"}
              fontWeight={"800"}
              letterSpacing={"8px"}
            >
              LOCATION
            </Text>
          </FlexBox>
        </DesktopLimit>
        <Map id="naver-map" isTablet={isTablet}></Map>
        <DesktopLimit maxWidth={"1200px"}>
          <FlexBox justifyContent={"center"}>
            <ForthTextWrap isMobile={isMobile}>
              <FlexBox
                margin={"40px 0 0 0"}
                flexDirection={isMobile && "column"}
                justifyContent={"space-between"}
              >
                <InfoItem isTablet={isTablet}>
                  <FlexBox alignItems={"center"}>
                    <SubTitleBar />
                    <Text size={"16"} fontWeight={"800"}>
                      주소
                    </Text>
                  </FlexBox>
                  <Text
                    margin={"8px 0 0 0"}
                    size={"14"}
                    color={"var(--color-SubBlack)"}
                  >
                    광주광역시 동구 지산로63번길 2 호산법조타운 206호
                  </Text>
                </InfoItem>
                <InfoItem isTablet={isTablet} margin={isMobile && "24px 0 0 0"}>
                  <FlexBox alignItems={"center"}>
                    <SubTitleBar />
                    <Text size={"16"} fontWeight={"800"}>
                      전화번호
                    </Text>
                  </FlexBox>
                  <Text
                    margin={"8px 0 0 0"}
                    size={"14"}
                    color={"var(--color-SubBlack)"}
                  >
                    062-231-2300
                  </Text>
                </InfoItem>
              </FlexBox>
              <FlexBox
                margin={"24px 0 0 0"}
                flexDirection={isMobile && "column"}
                justifyContent={"space-between"}
              >
                <InfoItem isTablet={isTablet}>
                  <FlexBox alignItems={"center"}>
                    <SubTitleBar />
                    <Text size={"16"} fontWeight={"800"}>
                      대중교통 이용시
                    </Text>
                  </FlexBox>
                  <Text
                    margin={"8px 0 0 0"}
                    size={"14"}
                    color={"var(--color-SubBlack)"}
                  >
                    광주지방법원 광장에서 조선대학교 방면으로
                    <br /> 70m 정도 걸어오시면 호산법조타운이 있습니다.
                  </Text>
                </InfoItem>
                <InfoItem isTablet={isTablet} margin={isMobile && "24px 0 0 0"}>
                  <FlexBox alignItems={"center"}>
                    <SubTitleBar />
                    <Text size={"16"} fontWeight={"800"}>
                      자가용 이용시
                    </Text>
                  </FlexBox>
                  <Text
                    margin={"8px 0 0 0"}
                    size={"14"}
                    color={"var(--color-SubBlack)"}
                  >
                    호산법조타운 인근 주차장을 이용하시면 됩니다.
                  </Text>
                </InfoItem>
              </FlexBox>
            </ForthTextWrap>
          </FlexBox>
        </DesktopLimit>
      </ForthSection>
    </CommonLayout>
  );
}

export default IntroductionPage;

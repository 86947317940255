import React from "react";
import { useMediaQuery } from "react-responsive";

import styled from "styled-components";

import GlobalHeader from "components/templates/GlobalHeader";
import GlobalFooter from "components/templates/GlobalFooter";

const ContentsSection = styled.div`
  margin: ${(props) => (props.isMobile ? "56px" : "160px")} 0 0 0;
  width: 100%;
  min-height: calc(100vh - ${(props) => (props.isMobile ? "56px" : "160px")});
  overflow: hidden;
`;

function CommonLayout(props) {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <>
      <GlobalHeader />
      <ContentsSection isMobile={isMobile}>{props.children}</ContentsSection>
      <GlobalFooter />
    </>
  );
}

export default CommonLayout;

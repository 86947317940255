import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

import styled from "styled-components";

import MailIcon from "assets/icons/MailIcon";
import HeaderLogo from "assets/HeaderLogo";
import MobileHeaderLogo from "assets/MobileHeaderLogo";
import HamburgerIcon from "assets/icons/HamburgerIcon";
import XIcon from "assets/icons/XIcon";

import DesktopLimit from "components/templates/DesktopLimit";
import FlexBox from "components/atoms/FlexBox";
import Text from "components/atoms/Text";
import GNBLinkButton from "components/atoms/GNBLinkButton";

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: auto;
  background-color: var(--color-White);
  z-index: 10000;
`;

const ContactHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  border-bottom: solid 1px var(--color-Outline);
`;

const MainHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 120px;
  border-bottom: solid 1px var(--color-Outline);
`;

const MobileHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 16px 0 12px;
  height: 56px;
  border-bottom: solid 1px var(--color-Outline);
  box-sizing: border-box;
  z-index: 1;
`;

const HamburgerButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border: transparent;
  background-color: var(--color-White);
  outline: none;
  cursor: pointer;
`;

const SideBarContainer = styled.div`
  position: fixed;
  top: 56px;
  right: ${(props) => props.right};
  width: 100vw;
  height: calc(100vh - 41px);
  background-color: white;
  // box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.24);
  transition: all 600ms ease-in-out;
  z-index: 10000;
`;

const SideBarMenuItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20%;
  border-bottom: solid 1px var(--color-Outline);
  background-color: var(--color-White);
  transition: all 150ms ease-in;
  cursor: pointer;

  &:active {
    background-color: var(--color-Key);
    p {
      color: var(--color-White);
    }
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

function GlobalHeader(props) {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTabletorPC = useMediaQuery({ minWidth: 768 });

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <Container>
      {isMobile && (
        <>
          <MobileHeader>
            <Link to={"/"}>
              <MobileHeaderLogo />
            </Link>
            <HamburgerButton onClick={toggleSidebar}>
              {sidebarOpen ? <XIcon /> : <HamburgerIcon />}
            </HamburgerButton>
          </MobileHeader>
          <SideBarContainer right={sidebarOpen ? "0" : "-100vw"}>
            {[
              { to: "/about", text: "해태 소개" },
              { to: "/major", text: "주요 업무" },
              { to: "/member", text: "변호사 소개" },
              { to: "/contact", text: "문의하기" },
            ].map((item, index) => (
              <StyledLink key={index} to={item.to}>
                <SideBarMenuItem>
                  <Text size={"20"}>{item.text}</Text>
                </SideBarMenuItem>
              </StyledLink>
            ))}
          </SideBarContainer>
        </>
      )}

      {isTabletorPC && (
        <>
          <ContactHeader>
            <DesktopLimit>
              <FlexBox justifyContent={"space-between"} alignItems={"center"}>
                <FlexBox>
                  <Text size={"12"} margin={"0 8px 0 0"} fontWeight={"600"}>
                    CONTACT
                  </Text>
                  <Text size={"12"} color={"#B6A391"} fontWeight={"600"}>
                    +82)062-231-2300
                  </Text>
                </FlexBox>
                <FlexBox>
                  <MailIcon />
                  <Text
                    size={"12"}
                    margin={"0 0 0 8px"}
                    color={"#B6A391"}
                    fontWeight={"600"}
                  >
                    lawhaetae@daum.net
                  </Text>
                </FlexBox>
              </FlexBox>
            </DesktopLimit>
          </ContactHeader>

          <MainHeader>
            <DesktopLimit>
              <FlexBox justifyContent={"center"} alignItems={"center"}>
                <FlexBox alignItems={"center"} margin={"0 80px 0 0"}>
                  <Link to="/about">
                    <GNBLinkButton>해태 소개</GNBLinkButton>
                  </Link>
                  <Link to="/major">
                    <GNBLinkButton>주요 업무</GNBLinkButton>
                  </Link>
                </FlexBox>
                <Link to={"/"}>
                  <HeaderLogo />
                </Link>
                <FlexBox alignItems={"center"} margin={"0 0 0 80px"}>
                  <Link to="/member">
                    <GNBLinkButton>변호사 소개</GNBLinkButton>
                  </Link>
                  <Link to="/contact">
                    <GNBLinkButton>문의하기</GNBLinkButton>
                  </Link>
                </FlexBox>
              </FlexBox>
            </DesktopLimit>
          </MainHeader>
        </>
      )}
    </Container>
  );
}

export default GlobalHeader;

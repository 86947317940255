import * as React from "react";

import styled from "styled-components";

const Svg = styled.svg`
  transform: scale(${(props) => props.scale});
`;

const SvgComponent = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    scale={props.scale}
    {...props}
  >
    <path
      stroke={props.color || "#B6A391"}
      strokeWidth={2}
      d="m5 7 2.5 2.5L11 6"
    />
    <rect
      width={14}
      height={14}
      x={1}
      y={1}
      stroke={props.color || "#B6A391"}
      strokeWidth={2}
      rx={7}
    />
  </Svg>
);
export default SvgComponent;

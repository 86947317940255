import React from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

import styled, { css } from "styled-components";

import LandingHeroBgImg from "assets/png/LandingHeroBgImg.png";
import LandingSubBgImg from "assets/png/LandingSubBgImg.png";

import CommonLayout from "components/templates/CommonLayout";
import FlexBox from "components/atoms/FlexBox";
import Text from "components/atoms/Text";

const FirstSection = styled.div`
  position: relative;
  width: 100%;
  height: ${(props) => (props.isMobile ? "calc(100vh - 80px)" : "720px")};
  min-height: 528px;
  background-color: var(--color-Background);
`;

const HeroImage = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: ${(props) => (props.isMobile ? "100%" : "65%")};
  height: 100%;
  background-image: url(${(props) => props.bgImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;

  > img {
    width: 100%;
  }
`;

const HeroBoxWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1400px;
  height: 100%;
  margin: 0 auto;
`;

const HeroBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.isMobile ? "center" : "flex-start")};
  padding: ${(props) => (props.isMobile ? "24px 16px" : "60px")};
  height: ${(props) => (props.isMobile ? "100%" : "auto")};
  box-sizing: border-box;

  ${(props) =>
    props.isMobile
      ? css`
          justify-content: center;
          background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.5) 0%,
            rgba(24, 18, 18, 0.34) 53.12%,
            rgba(75, 56, 56, 0) 100%
          );
          width: 100%;
        `
      : css`
          background-color: var(--color-Box);
        `}
`;

const HanjaFont = styled.span`
  font-family: auto;
`;

const ContactButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 120px 0 0 0;
  width: ${(props) => (props.isMobile ? "120px" : "200px")};
  height: ${(props) => (props.isMobile ? "48px" : "60px")};
  border: transparent;
  border-radius: 2px;
  background-color: var(--color-White);
  outline: none;
  cursor: pointer;
  transition: all 150ms ease-in;

  &:hover {
    background-color: var(--color-Key);

    p {
      color: var(--color-White);
    }
  }
`;

const SecondSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 240px 0;
  width: 100%;
`;

const Divider = styled.span`
  display: block;
  margin: 40px 0;
  width: 24px;
  height: 2px;
  background-color: var(--color-Key);
`;

const Red = styled.span`
  color: var(--color-Key);
`;

const ThirdSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 680px;
`;

const ThirdBackgroundImage = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 680px;
  background-image: url(${(props) => props.bgImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
`;

function LandingPage(props) {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTabletorPC = useMediaQuery({ minWidth: 768 });

  return (
    <CommonLayout>
      {/* 첫번째 섹션 */}
      <FirstSection isMobile={isMobile}>
        <HeroImage isMobile={isMobile} bgImage={LandingHeroBgImg} />
        <HeroBoxWrapper>
          <HeroBox isMobile={isMobile}>
            <FlexBox flexDirection={"column"} width={"100%"}>
              <Text
                size={isMobile ? "12" : "14"}
                color={"var(--color-White)"}
                fontWeight={"600"}
                letterSpacing={isMobile ? "8px" : "16px"}
                textAlign={isMobile ? "center" : "left"}
              >
                LAW GROUP
              </Text>
              <Text
                size={isMobile ? "20" : "32"}
                color={"var(--color-White)"}
                fontWeight={"600"}
                margin={"16px 0 0 0;"}
                lineHeight={"1.5"}
                letterSpacing={"4px"}
                textAlign={isMobile ? "center" : "left"}
              >
                시비(<HanjaFont>是非</HanjaFont>)·선악(
                <HanjaFont>善惡</HanjaFont>){isMobile && <br />}판별의 상징
                <br />
                법무법인 해태
              </Text>
              <Text
                size={isMobile ? "14" : "16"}
                color={"var(--color-White)"}
                margin={"16px 0 0 0;"}
                lineHeight={"1.5"}
                letterSpacing={"1px"}
                textAlign={isMobile ? "center" : "left"}
              >
                법무법인 해태는 정직하고 정의로운 과정으로
                <br />
                올바른 결과를 만듭니다.
              </Text>
            </FlexBox>
            <Link to={"/contact"}>
              <ContactButton isMobile={isMobile}>
                <Text size={isMobile ? "16" : "18"} fontWeight={"600"}>
                  문의하기
                </Text>
              </ContactButton>
            </Link>
          </HeroBox>
        </HeroBoxWrapper>
      </FirstSection>

      {/* 두번째 섹션 */}
      <SecondSection>
        <Text
          size={"14"}
          color={"var(--color-Key)"}
          fontWeight={"800"}
          letterSpacing={isMobile ? "8px" : "12px"}
        >
          OUR PHILOSOPHY
        </Text>
        <Divider />
        <Text
          size={isMobile ? "20" : "24"}
          fontWeight={"800"}
          textAlign={"center"}
          letterSpacing={"2px"}
          lineHeight={"1.6"}
        >
          <Red>법무법인 해태</Red>는
          <br />
          <Red>정직</Red>하고 <Red>정의</Red>로운 과정으로 {isMobile && <br />}
          올바른 결과를 만듭니다.
        </Text>
      </SecondSection>

      {/* 세번째 섹션 */}
      <ThirdSection>
        <ThirdBackgroundImage bgImage={LandingSubBgImg} />
        <Text
          size={"14"}
          fontWeight={"800"}
          letterSpacing={isMobile ? "8px" : "12px"}
          color={"var(--color-White)"}
          textAlign={"center"}
        >
          THE BEST PARTNER
        </Text>
        <Text
          margin={"40px 0 0 0"}
          size={isMobile ? "20" : "32"}
          fontWeight={"800"}
          letterSpacing={"2px"}
          color={"var(--color-White)"}
          lineHeight={"1.6"}
          textAlign={"center"}
        >
          오랜 친구처럼 {isMobile && <br />}편안하고 믿음직한 파트너
        </Text>
        <Text
          margin={"40px 0 0 0"}
          size={isMobile ? "14" : "16"}
          fontWeight={"400"}
          letterSpacing={"2px"}
          color={"var(--color-White)"}
          textAlign={"center"}
          lineHeight={"1.6"}
        >
          법무법인 해태는 의뢰기간 동안 {isMobile && <br />}고객의 이익을
          최우선으로 생각하며,
          <br />
          신속하고 전문적인 법률 서비스를 제공합니다.
        </Text>
      </ThirdSection>
    </CommonLayout>
  );
}

export default LandingPage;

import React from "react";

import styled from "styled-components";

import Text from "components/atoms/Text";

const Button = styled.button`
  margin: ${(props) => props.margin || "0"};
  padding: 16px 24px;
  background-color: var(--color-White);
  border: transparent;
  outline: none;
  cursor: pointer;
  transition: all 150ms ease-in;

  &:hover {
    p {
      color: var(--color-Key);
    }
  }
`;

function GNBLinkButton(props) {
  return (
    <Button margin={props.margin} onClick={props.onClick}>
      <Text size={"14"} fontWeight={"600"}>
        {props.children}
      </Text>
    </Button>
  );
}

export default GNBLinkButton;

import * as React from "react";
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#1E1E1E"
      d="M0 0h16v1.333H0zM0 7.333h16v1.333H0zM0 14.667h16V16H0z"
    />
  </svg>
);
export default SvgComponent;

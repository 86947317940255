import React from "react";
import { useMediaQuery } from "react-responsive";

import styled from "styled-components";

import FlexBox from "components/atoms/FlexBox";
import Text from "components/atoms/Text";

const Container = styled.div`
  margin: ${(props) => props.margin};
  width: 100%;
  max-width: ${(props) => props.maxWidth};
`;

const Label = styled.label`
  font-size: 16px;
  color: var(--color-Black);
`;

const Input = styled.input`
  margin: 4px 0 0 0;
  padding: 0 16px;
  width: 100%;
  height: 48px;
  font-size: 13px;
  color: var(--color-SubBlack);
  box-sizing: border-box;
  border: solid 1px var(--color-InputLine);
  outline: none;

  &:placeholder {
    color: var(--color-Grey1);
  }
`;

const Textarea = styled.textarea`
  margin: 4px 0 0 0;
  padding: 12px 16px;
  width: 100%;
  height: ${(props) => props.textareaHeight || "200px"};
  font-size: 13px;
  color: var(--color-SubBlack);
  border: solid 1px var(--color-InputLine);
  outline: none;
  resize: none;
  box-sizing: border-box;

  &:placeholder {
    color: var(--color-Grey1);
  }
`;

function LabelInput(props) {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Container margin={props.margin} maxWidth={props.maxWidth}>
      <FlexBox alignItems={"flex-end"}>
        <Label for={props.id}>{props.labelName}</Label>
        {props.subText && (
          <Text margin={"0 0 0 8px"} size={"13"} color={"var(--color-Key)"}>
            {props.subText}
          </Text>
        )}
      </FlexBox>
      {props.input && (
        <Input
          id={props.id}
          type={props.type}
          value={props.value}
          onChange={props.onChange}
          placeholder={props.placeholder}
        />
      )}
      {props.textarea && (
        <Textarea
          id={props.id}
          type={props.type}
          value={props.value}
          onChange={props.onChange}
          placeholder={props.placeholder}
        />
      )}
    </Container>
  );
}

export default LabelInput;
